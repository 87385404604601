<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->

<!-- Google tag (gtag.js) -->

<template>
  <div class="min-h-screen bg-gray-900">
    <div class="relative overflow-hidden">
      <Popover as="header" class="relative">
        <div class="bg-gray-800 pt-6">
          <nav
            class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div class="flex items-center">
              <div class="flex w-full md:w-auto">
                <a href="#">
                  <span class="sr-only">Workflow</span>
                  <img
                    class="h-20 w-auto sm:h-24"
                    src="../assets/corecontrols_dark_theme_1200_thick_tailwind.png"
                    alt=""
                  />
                </a>
                <div class="-mr-2 flex items-center md:hidden">
                  <PopoverButton
                    class="bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white"
                  >
                    <span class="sr-only">Open main menu</span>
                    <MenuIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
            </div>
            <div class="flex justify-end">
              <div class="flex space-x-8 md:flex md:ml-10">
                <a
                  v-for="item in navigation"
                  :key="item.name"
                  :href="item.href"
                  class="flex text-base font-medium text-white hover:text-gray-300"
                  >{{ item.name }}</a
                >
              </div>
            </div>
            <div class="hidden md:flex md:items-center md:space-x-6">
              <a
                :href="core_client_url"
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                Dashboard
              </a>
            </div>
          </nav>
        </div>

        <transition
          enter-active-class="duration-150 ease-out"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <PopoverPanel
            focus
            class="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
          >
            <div
              class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden"
            >
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    class="h-12 w-auto"
                    src="../assets/corethings_light_theme_1200_text_thicker.png"
                    alt=""
                  />
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="pt-5 pb-6">
                <div class="px-2 space-y-1">
                  <a
                    v-for="item in navigation"
                    :key="item.name"
                    :href="item.href"
                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >{{ item.name }}</a
                  >
                </div>
                <div class="mt-6 px-5">
                  <a
                    :href="core_client_url"
                    class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
                    >Dashboard</a
                  >
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>

      <main>
        <div
          class="pt-0 bg-gray-800 sm:pt-0 lg:pt-0 lg:pb-14 lg:overflow-hidden"
        >
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <!-- left side splash  -->

              <div class="">
                <img
                  class="w-full"
                  src="../assets/measure-meter-1.png"
                  alt=""
                />
              </div>

              <div
                class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center"
              >
                <div class="lg:py-10">
                  <h1
                    class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl"
                  >
                    <span class="block">Real-Time</span>
                    <span class="block text-indigo-400">Energy Metering</span>
                  </h1>
                  <!-- <p
                    class="mt-3 text-base text-gray-300 sm:mt-20 sm:text-xl lg:text-lg xl:text-xl"
                  >
                    Using plug-and-play technologies, bring your building into
                    the 21st Century. Real-time monitoring of Energy and Water
                    usage, power consumption of assets, retrofit legacy HVAC
                    systems with real-time controls and so much more. All done
                    with our proprietary no-code solution
                  </p> -->
                  <p
                    class="mt-3 text-base text-gray-300 sm:mt-20 sm:text-xl lg:text-lg xl:text-xl"
                  >
                    Are you ready for Europe's Energy Crisis? Real-time Energy
                    Metering helps you MEASURE, CONTROL and REDUCE your energy
                    consumption. Plug-and-play sensors on legacy meters.
                    Retrofit legacy HVAC systems with easy controls. Turn your
                    building into a SMART building
                  </p>

                  <div v-if="!emailSubmitted" class="mt-10 sm:mt-20">
                    <!-- start of email form -->

                    <div class="sm:flex">
                      <div class="min-w-0 flex-1">
                        <label for="email" class="sr-only">Email address</label>
                        <input
                          v-model="signup.email"
                          type="email"
                          placeholder="Enter your email"
                          class="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                        />
                      </div>
                      <div class="mt-3 sm:mt-0 sm:ml-3">
                        <button
                          type="submit"
                          v-on:click="submitSignup"
                          class="block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                        >
                          I'm interested!
                        </button>
                      </div>
                    </div>

                    <!-- end of email form -->

                    <p class="mt-3 text-sm text-gray-300 sm:mt-4">
                      Currently in Private Beta. Please sign up if you're
                      interested in knowing more.
                    </p>
                  </div>
                  <div v-else>
                    <p class="mt-3 text-lg text-gray-300 sm:mt-4 p-5">
                      Thank you for submitting your interest. As soon as we
                      start opening up the platform, we will let you know!
                    </p>
                  </div>
                </div>
              </div>
              <!-- right side splash  -->
            </div>
          </div>
        </div>

        <img src="../assets/blue-gray-transition-clean.png" />
        <div class="bg-gray-200 p-10">
          <div
            class="bg-gray-500 rounded-lg border-dashed border-0 border-gray-200"
          >
            <div class="lg:grid lg:grid-cols-3">
              <div class="col-span-1 pt-3 pb-3 lg:pl-12 lg:pr-12 pl-0 pr-0">
                <!-- start card -->
                <button
                  class="grid grid-cols-4 bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden w-72 h-36 m-10 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                  v-bind:class="{
                    'outline-none ring-2 ring-offset-2 ring-green-400 bg-gray-200':
                      panel_measure == true,
                  }"
                  v-on:click="
                    (panel_measure = true),
                      (panel_control = false),
                      (panel_reduce = false)
                  "
                >
                  <div>
                    <ChartSquareBarIcon
                      class="col-span-1 h-9 w-9 text-green-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="col-span-3">
                    <p
                      class="text-left text-2xl font-semibold text-gray-700 truncate"
                    >
                      Measure
                    </p>

                    <p class="text-left text-sm font-medium text-gray-500">
                      Measure both the Inputs AND the Outputs
                    </p>
                  </div>
                </button>
                <!-- end card -->
                <!-- start card -->
                <button
                  class="grid grid-cols-4 bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden w-72 h-36 m-10 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                  v-bind:class="{
                    'outline-none ring-2 ring-offset-2 ring-green-400 bg-gray-200':
                      panel_control == true,
                  }"
                  v-on:click="
                    (panel_control = true),
                      (panel_measure = false),
                      (panel_reduce = false)
                  "
                >
                  <div>
                    <ChartSquareBarIcon
                      class="col-span-1 h-9 w-9 text-green-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="col-span-3">
                    <p
                      class="text-left text-2xl font-semibold text-gray-700 truncate"
                    >
                      Control
                    </p>

                    <p class="text-left text-sm font-medium text-gray-500">
                      Replace complex building controls with simple rules
                    </p>
                  </div>
                </button>
                <!-- end card -->
                <!-- start card -->
                <button
                  class="grid grid-cols-4 bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden w-72 h-36 m-10 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
                  v-bind:class="{
                    'outline-none ring-2 ring-offset-2 ring-green-400 bg-gray-200':
                      panel_reduce == true,
                  }"
                  v-on:click="
                    (panel_reduce = true),
                      (panel_control = false),
                      (panel_measure = false)
                  "
                >
                  <div>
                    <ChartSquareBarIcon
                      class="col-span-1 h-9 w-9 text-green-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="col-span-3">
                    <p
                      class="text-left text-2xl font-semibold text-gray-700 truncate"
                    >
                      Reduce
                    </p>

                    <p class="text-left text-sm font-medium text-gray-500">
                      Track the impact of your changes real-time
                    </p>
                  </div>
                </button>
                <!-- end card -->
              </div>

              <div
                v-if="panel_measure"
                class="flex col-span-2 text-gray-100 p-6 lg:m-6 m-0 border-2 border-white rounded-lg bg-gray-100"
              >
                <div>
                  <p class="text-sm font-medium text-green-600 px-6 mb-6">
                    INPUTS
                  </p>

                  <p class="text-sm font-small text-gray-500 px-3">
                    Industry leading optical sensors easily retrofitted to
                    existing meters. Measure Electricity, Gas, Water at the
                    point of building entry.
                  </p>
                  <img
                    src="../assets/fludia_hand.png"
                    class="object-cover w-96"
                  />
                </div>
                <div>
                  <p class="text-sm font-medium text-green-600 px-6 mb-6">
                    OUTPUTS
                  </p>

                  <p class="text-sm font-small text-gray-500 px-3">
                    Environmental sensors to track temperature and humidity of
                    your entire facility. Current sensors to assess power
                    consumption of individual assets.
                  </p>
                  <img
                    src="../assets/output-sensors.png"
                    class="object-cover w-96 pl-12"
                  />
                </div>
              </div>
              <div
                v-if="panel_control"
                class="col-span-2 text-gray-100 lg:p-12 p-2 border-1"
              >
                <div>
                  <img src="../assets/control-panel.png" class="rounded-lg" />
                </div>
              </div>
              <div
                v-if="panel_reduce"
                class="col-span-2 text-gray-100 lg:p-12 p-2 border-1"
              >
                <div>
                  <img src="../assets/reduce-panel.png" class="rounded-lg" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <img src="../assets/gray-white-transition-clean.png" />

        <!-- ## New Slide ## Scan Install ##   -->
        <div class="py-0 bg-white overflow-hidden lg:py-0">
          <div
            class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl"
          >
            <svg
              class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-6"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>

            <div class="relative mt-6 sm:mt-16 lg:mt-0">
              <div
                class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center"
              >
                <div class="lg:col-start-2">
                  <h3
                    class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl"
                  >
                    Scan to Install
                  </h3>
                  <p class="mt-3 text-lg text-gray-500">
                    Scanning the QR codes on the Sensors automatically adds the
                    sensor to your network.
                  </p>

                  <dl class="mt-10 space-y-10">
                    <div
                      v-for="item in scan_features"
                      :key="item.id"
                      class="relative"
                    >
                      <dt>
                        <div
                          class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                        >
                          <component
                            :is="item.icon"
                            class="h-6 w-6"
                            aria-hidden="true"
                          />
                        </div>
                        <p
                          class="ml-16 text-lg leading-6 font-medium text-gray-900"
                        >
                          {{ item.name }}
                        </p>
                      </dt>
                      <dd class="mt-2 ml-16 text-base text-gray-500">
                        {{ item.description }}
                      </dd>
                    </div>
                  </dl>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <img
                    class="relative mx-auto"
                    width="800"
                    src="../assets/iphone_scan_2.gif"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ### New Slide ### Sensor ###   -->

        <!-- <div class="bg-gray-200 overflow-hidden">
      <div class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <svg
          class="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div class="lg:col-span-1">
            <h2
              class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
            >
              Latest Industry LoRaWAN Sensors
            </h2>
          </div>
          <dl
            class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2"
          >
            <div v-for="feature in sensor_features" :key="feature.name">
              <dt>
                <div
                  class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                >
                  <component
                    :is="feature.icon"
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                </div>
                <p class="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {{ feature.name }}
                </p>
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                {{ feature.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div> -->
        <!-- ### New Slide ### Gateway ###   -->

        <!-- <img src="../assets/gray-blue-transition-clean.png" /> -->
        <!-- <div class="relative py-0 bg-gray-800 sm:py-0 lg:py-0 lg:mb-0">
      <div
        class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl pb-20"
      >
        <h2
          class="text-base font-semibold tracking-wider text-purple-400 uppercase"
        >
          Global Coverage
        </h2>
        <div>
          <div
            class="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
          >
            The coreGateway
          </div>
        </div>
        <p class="mt-5 max-w-prose mx-auto text-xl text-gray-200">
          GSM Data included with global coverage
        </p>
        <div class="mt-12">
          <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <div
              v-for="feature in gateway_features"
              :key="feature.name"
              class="pt-6"
            >
              <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                <div class="-mt-6">
                  <div>
                    <span
                      class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg"
                    >
                      <component
                        :is="feature.icon"
                        class="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <h3
                    class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                  >
                    {{ feature.name }}
                  </h3>
                  <p class="mt-5 text-base text-gray-500">
                    {{ feature.detail }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="../assets/blue-gray-transition-clean.png" />
    </div> -->

        <div><img src="../assets/white-gray-transition-clean.png" /></div>

        <!-- ### New Slide ### Dashboard ###   -->

        <div class="bg-gray-200 overflow-hidden">
          <div
            class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-10 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"
          >
            <div>
              <h2
                class="text-base font-semibold text-indigo-600 uppercase tracking-wide"
              >
                Everything you need
              </h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900">
                Application Dashboard
              </p>
              <!-- <p class="mt-4 text-lg text-gray-500">
            Deploy and manage your Sensor network through pre-built applications
            and dashboards
          </p> -->

              <div class="mt-8">
                <img
                  class="rounded-xl shadow-xl"
                  src="../assets/dashboard_v1.png"
                />
              </div>
            </div>
            <div class="mt-6 lg:mt-24 lg:col-span-2">
              <dl
                class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-4"
              >
                <div
                  v-for="feature in dashboard_features"
                  :key="feature.name"
                  class="relative"
                >
                  <dt>
                    <CheckIcon
                      class="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p class="ml-9 text-lg leading-6 font-medium text-gray-900">
                      {{ feature.name }}
                    </p>
                  </dt>
                  <dd class="mt-2 ml-9 text-base text-gray-500">
                    {{ feature.description }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <img src="../assets/gray-white-transition-clean.png" />
        </div>
        <div class="py-0 bg-white overflow-hidden lg:py-0">
          <div
            class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl"
          >
            <svg
              class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              />
            </svg>

            <div class="relative">
              <h2
                class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >
                A Unified Sensor Platform
              </h2>
              <p
                class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500"
              >
                A private LoRaWAN network that allows you deploy and manage
                Industry's latest sensor technology at scale.
              </p>
            </div>

            <div
              class="relative mt-5 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
            >
              <div class="relative">
                <h3
                  class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl"
                >
                  Plug & Play Sensor Stack
                </h3>
                <p class="mt-3 text-lg text-gray-500"></p>

                <dl class="mt-10 space-y-10">
                  <div
                    v-for="item in top_level_features"
                    :key="item.id"
                    class="relative"
                  >
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                      >
                        <component
                          :is="item.icon"
                          class="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p
                        class="ml-16 text-lg leading-6 font-medium text-gray-900"
                      >
                        {{ item.name }}
                      </p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      {{ item.description }}
                    </dd>
                  </div>
                </dl>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        class="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  class="relative mx-auto"
                  width="490"
                  src="../assets/tech-stack-layers.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <img src="../assets/white-blue-transition-clean.png" />
          </div>

          <!-- ### New Slide ## Data Point Animation -->
          <div class="bg-gray-800 overflow-hidden pt-10">
            <div
              class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-0 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"
            >
              <div class="lg:col-span-2 pr-0">
                <div class="lg:px-0 mt-0">
                  <h2
                    class="text-base font-semibold text-yellow-300 uppercase tracking-wide"
                  >
                    End-to-end Transport Layer
                  </h2>
                  <p class="mt-2 mb-10 text-4xl font-extrabold text-gray-50">
                    coreControls Platform
                  </p>
                </div>

                <div class="flow-root bg-gray-700 rounded-lg">
                  <!-- <img class="rounded-xl shadow-xl" src="../assets/dashboard.png" /> -->

                  <div>
                    <svg
                      viewBox="0 0 450 200"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle r="2" fill="white" transform="translate(65,40)">
                        <animateMotion
                          id="op"
                          dur="3s"
                          path="M 0 6 80 46 200 46 300 6"
                          repeatCount="indefinite"
                        />
                        />
                      </circle>
                      <circle r="2" fill="white" transform="translate(65,80)">
                        <animateMotion
                          dur="3s"
                          path="M 0,6 h 300"
                          repeatCount="indefinite"
                        />
                        />
                      </circle>
                      <circle r="2" fill="white" transform="translate(65,120)">
                        <animateMotion
                          dur="3s"
                          path="M 0,6 80 -36 200 -36 300 6"
                          repeatCount="indefinite"
                        />
                        />
                      </circle>
                      <path
                        fill="white"
                        transform="translate(50,40)"
                        d="M5.18 5.45L3.4 4.55L4.66 2H13.13L14.4 4.55L12.62 5.44L11.9 4H5.9L5.18 5.45M15.5 8H11L10.2 5H7.6L6.81 8H2.28L1 10.55L2.79 11.44L3.5 10H14.28L15 11.45L16.79 10.56L15.5 8M14.67 22H12.6L12.36 21.1L8.9 15.9L5.43 21.1L5.2 22H3.13L6 11H8.09L7.73 12.35L8.9 14.1L10.06 12.35L9.71 11H11.78L14.67 22M8.3 15L7.4 13.65L6.22 18.13L8.3 15M11.58 18.12L10.4 13.64L9.5 15L11.58 18.12M23 16L19 12V15H15V17H19V20L23 16Z"
                      />
                      <path
                        fill="white"
                        transform="translate(50,80)"
                        d="M15 13V5A3 3 0 0 0 9 5V13A5 5 0 1 0 15 13M12 4A1 1 0 0 1 13 5V8H11V5A1 1 0 0 1 12 4Z"
                      />
                      <path
                        fill="white"
                        transform="translate(50,120)"
                        d="M12 2L2 12H4V21H20V12H22M12 5L18 11V19H6V11M11.5 14H9L12.5 7V11H15L11.5 18V14Z"
                      />
                      <path
                        fill="white"
                        transform="translate(150,70)"
                        d="M20.2,5.9L21,5.1C19.6,3.7 17.8,3 16,3C14.2,3 12.4,3.7
                11,5.1L11.8,5.9C13,4.8 14.5,4.2 16,4.2C17.5,4.2 19,4.8
                20.2,5.9M19.3,6.7C18.4,5.8 17.2,5.3 16,5.3C14.8,5.3 13.6,5.8
                12.7,6.7L13.5,7.5C14.2,6.8 15.1,6.5 16,6.5C16.9,6.5 17.8,6.8
                18.5,7.5L19.3,6.7M19,13H17V9H15V13H5A2,2 0 0,0 3,15V19A2,2 0 0,0
                5,21H19A2,2 0 0,0 21,19V15A2,2 0 0,0
                19,13M8,18H6V16H8V18M11.5,18H9.5V16H11.5V18M15,18H13V16H15V18Z"
                      />
                      <circle
                        transform="translate(200,50)"
                        cx="40px"
                        cy="40px"
                        r="14px"
                        fill="white"
                        visibility="visible"
                      ></circle>
                      <circle
                        transform="translate(200,50)"
                        cx="40px"
                        cy="40px"
                        r="20px"
                        fill="none"
                        stroke="white"
                        stroke-width="1.5px"
                        visibility="visible"
                      ></circle>
                      <circle
                        transform="translate(200,60)"
                        cx="40px"
                        cy="10px"
                        r="3px"
                        fill="white"
                        stroke="none"
                        visibility="visible"
                      ></circle>
                      <circle
                        transform="translate(190,53)"
                        cx="70px"
                        cy="35px"
                        r="3px"
                        fill="white"
                        stroke="none"
                        visibility="visible"
                      ></circle>
                      <path
                        fill="white"
                        transform="translate(350,40)"
                        d="M21,14V4H3V14H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14L16,21V22H8V21L10,18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M4,5H15V10H4V5M16,5H20V7H16V5M20,8V13H16V8H20M4,11H9V13H4V11M10,11H15V13H10V11Z"
                      />
                      <path
                        fill="white"
                        transform="translate(350,80)"
                        d="M7 7H5A2 2 0 0 0 3 9V17H5V13H7V17H9V9A2 2 0 0 0 7 7M7 11H5V9H7M14 7H10V17H12V13H14A2 2 0 0 0 16 11V9A2 2 0 0 0 14 7M14 11H12V9H14M20 9V15H21V17H17V15H18V9H17V7H21V9Z"
                      />
                      <path
                        fill="white"
                        transform="translate(350,120)"
                        d="M10.46,19C9,21.07 6.15,21.59 4.09,20.15C2.04,18.71 1.56,15.84 3,13.75C3.87,12.5 5.21,11.83 6.58,11.77L6.63,13.2C5.72,13.27 4.84,13.74 4.27,14.56C3.27,16 3.58,17.94 4.95,18.91C6.33,19.87 8.26,19.5 9.26,18.07C9.57,17.62 9.75,17.13 9.82,16.63V15.62L15.4,15.58L15.47,15.47C16,14.55 17.15,14.23 18.05,14.75C18.95,15.27 19.26,16.43 18.73,17.35C18.2,18.26 17.04,18.58 16.14,18.06C15.73,17.83 15.44,17.46 15.31,17.04L11.24,17.06C11.13,17.73 10.87,18.38 10.46,19M17.74,11.86C20.27,12.17 22.07,14.44 21.76,16.93C21.45,19.43 19.15,21.2 16.62,20.89C15.13,20.71 13.9,19.86 13.19,18.68L14.43,17.96C14.92,18.73 15.75,19.28 16.75,19.41C18.5,19.62 20.05,18.43 20.26,16.76C20.47,15.09 19.23,13.56 17.5,13.35C16.96,13.29 16.44,13.36 15.97,13.53L15.12,13.97L12.54,9.2H12.32C11.26,9.16 10.44,8.29 10.47,7.25C10.5,6.21 11.4,5.4 12.45,5.44C13.5,5.5 14.33,6.35 14.3,7.39C14.28,7.83 14.11,8.23 13.84,8.54L15.74,12.05C16.36,11.85 17.04,11.78 17.74,11.86M8.25,9.14C7.25,6.79 8.31,4.1 10.62,3.12C12.94,2.14 15.62,3.25 16.62,5.6C17.21,6.97 17.09,8.47 16.42,9.67L15.18,8.95C15.6,8.14 15.67,7.15 15.27,6.22C14.59,4.62 12.78,3.85 11.23,4.5C9.67,5.16 8.97,7 9.65,8.6C9.93,9.26 10.4,9.77 10.97,10.11L11.36,10.32L8.29,15.31C8.32,15.36 8.36,15.42 8.39,15.5C8.88,16.41 8.54,17.56 7.62,18.05C6.71,18.54 5.56,18.18 5.06,17.24C4.57,16.31 4.91,15.16 5.83,14.67C6.22,14.46 6.65,14.41 7.06,14.5L9.37,10.73C8.9,10.3 8.5,9.76 8.25,9.14Z"
                      />
                      <path
                        fill="lightgrey"
                        transform="translate(155,95) scale(0.500000,0.5000000)"
                        d="M11,16.5V14.25C11,13 10,12 8.75,12C10,12 11,11 11,9.75V7.5A3,3 0 0,0 8,4.5H2V7.5H8V10.5H5V13.5H8V16.5H2V19.5H8A3,3 0 0,0 11,16.5M22,16.5V10.5H17.5V13.5H19V16.5H16V7.5H22V4.5H16A3,3 0 0,0 13,7.5V16.5A3,3 0 0,0 16,19.5H19A3,3 0 0,0 22,16.5Z"
                      />

                      <g
                        transform="translate(40,190) scale(0.0200000,-0.02000000)"
                        fill="lightgrey"
                        stroke="none"
                      >
                        <path
                          d="M672 1174 c-24 -8 -57 -23 -73 -33 -25 -15 -27 -20 -16 -35 13 -17
15 -17 53 2 95 48 212 49 305 2 44 -23 69 -21 69 5 0 10 -23 26 -57 42 -74 33
-214 42 -281 17z"
                        />
                        <path
                          d="M705 1086 c-22 -7 -51 -18 -64 -25 -20 -11 -21 -15 -9 -27 12 -12 19
-12 49 3 53 27 144 29 209 4 32 -12 58 -17 63 -12 15 15 -1 29 -59 50 -61 23
-129 26 -189 7z"
                        />
                        <path
                          d="M380 815 l0 -195 120 0 120 0 0 40 0 40 -75 0 -75 0 0 155 0 155 -45
0 -45 0 0 -195z"
                        />
                        <path
                          d="M718 995 c-34 -11 -46 -19 -42 -30 7 -18 12 -18 44 0 33 19 102 19
147 0 43 -18 43 -18 43 -2 0 36 -119 55 -192 32z"
                        />
                        <path
                          d="M1000 815 l0 -195 45 0 45 0 0 70 0 70 30 0 c27 0 33 -7 65 -70 l35
-70 51 0 51 0 -41 74 -41 74 35 35 c30 30 35 42 35 81 0 103 -37 126 -199 126
l-111 0 0 -195z m208 113 c7 -7 12 -27 12 -45 0 -40 -21 -53 -84 -53 l-46 0 0
55 0 55 53 0 c29 0 58 -5 65 -12z"
                        />
                        <path
                          d="M1660 815 l0 -195 44 0 44 0 59 93 58 92 3 -93 3 -93 46 3 47 3 93
145 c52 80 107 166 123 193 l30 47 -53 0 -53 0 -69 -107 -70 -108 -3 108 -3
107 -35 0 c-33 0 -38 -5 -104 -107 l-70 -107 0 107 0 107 -45 0 -45 0 0 -195z"
                        />
                        <path
                          d="M2165 824 c-66 -101 -121 -189 -123 -194 -2 -6 17 -10 42 -10 39 0
49 4 68 30 20 27 27 30 79 30 55 0 58 -1 61 -27 3 -25 7 -28 46 -31 l42 -3 0
195 0 196 -47 0 -48 -1 -120 -185z m125 -14 c0 -49 0 -50 -31 -50 -17 0 -29 3
-27 8 6 14 53 92 56 92 1 0 2 -22 2 -50z"
                        />
                        <path
                          d="M2450 816 l0 -196 40 0 40 0 0 101 c0 59 4 98 9 94 5 -3 36 -48 67
-100 l58 -95 48 0 48 0 0 195 0 195 -45 0 -44 0 -3 -102 -3 -102 -61 99 -61
100 -46 3 -47 3 0 -195z"
                        />
                        <path
                          d="M2826 994 c-39 -38 6 -110 53 -84 30 15 38 56 16 80 -22 24 -48 26
-69 4z m66 -20 c11 -23 10 -29 -10 -45 -28 -22 -50 -15 -58 17 -13 51 47 75
68 28z"
                        />
                        <path
                          d="M2840 953 c0 -22 2 -25 10 -13 8 12 11 13 19 1 6 -9 9 -6 9 13 0 19
-6 26 -19 26 -14 0 -19 -7 -19 -27z"
                        />
                        <path
                          d="M721 902 c-52 -28 -73 -74 -69 -149 3 -53 8 -67 33 -93 74 -77 202
-58 241 36 59 140 -77 275 -205 206z m114 -67 c19 -18 25 -35 25 -67 0 -59
-22 -88 -65 -88 -44 0 -65 29 -65 91 0 39 5 51 26 68 34 27 50 26 79 -4z"
                        />
                        <path
                          d="M1409 905 c-15 -8 -32 -28 -37 -45 l-11 -30 40 0 c26 0 42 5 45 15
14 35 74 27 74 -9 0 -19 -8 -25 -55 -35 -68 -15 -104 -45 -111 -91 -12 -72 67
-121 138 -86 20 11 34 13 36 6 2 -5 19 -10 38 -10 l34 0 0 121 c0 66 -4 129
-10 139 -20 38 -130 53 -181 25z m111 -175 c0 -21 -8 -35 -26 -47 -32 -20 -59
-8 -59 27 0 17 9 26 35 36 49 18 50 17 50 -16z"
                        />
                        <path
                          d="M675 570 c-10 -16 58 -43 115 -44 61 -1 120 19 120 40 0 17 0 17 -43
-1 -20 -8 -52 -15 -72 -15 -20 0 -52 7 -72 15 -42 18 -40 18 -48 5z"
                        />
                        <path
                          d="M633 503 c-4 -9 2 -22 13 -30 28 -20 112 -43 158 -43 52 1 151 40
151 60 0 18 -15 17 -75 -5 -57 -21 -142 -16 -199 12 -37 19 -43 19 -48 6z"
                        />
                        <path
                          d="M582 429 c-11 -17 -8 -22 20 -39 94 -58 245 -66 351 -17 58 27 63 32
48 56 -7 11 -17 10 -54 -8 -95 -46 -210 -45 -304 3 -48 24 -49 24 -61 5z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="mt-6 lg:mt-0 lg:col-span-1">
                <dl
                  class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-4"
                >
                  <div
                    v-for="feature in platform_features"
                    :key="feature.name"
                    class="relative"
                  >
                    <dt>
                      <component
                        :is="feature.icon"
                        class="absolute h-12 w-12 text-yellow-300"
                        aria-hidden="true"
                      />
                      <!-- <CheckIcon
                  class="absolute h-6 w-6 text-yellow-300"
                  aria-hidden="true"
                /> -->
                      <p
                        class="ml-9 text-lg leading-6 font-medium text-gray-50"
                      >
                        {{ feature.name }}
                      </p>
                    </dt>
                    <dd class="mt-2 ml-9 text-base text-gray-100">
                      {{ feature.detail }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>

          <div>
            <img src="../assets/blue-white-transition-clean.png" />
          </div>

          <!-- ## New Slide ## Webhook ##  -->

          <div class="relative bg-white pt-0 pb-0 overflow-hidden">
            <div class="relative"></div>
            <div class="mt-24">
              <div
                class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
              >
                <div
                  class="px-4 max-w-xl mx-auto sm:px-6 lg:pb-64 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1"
                >
                  <div>
                    <div>
                      <span
                        class="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600"
                      >
                        <Webhook
                          class="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div class="mt-6">
                      <h2
                        class="text-3xl font-extrabold tracking-tight text-gray-900"
                      >
                        Webhook Integration
                      </h2>
                      <p class="mt-4 text-lg text-gray-500">
                        Automatically send ALL DATA from the coreControls
                        network directly into your own internal systems. This
                        will give you full control over the data coming from
                        your assets.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
                  <div
                    class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
                  >
                    <img
                      class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-3/4 lg:w-auto lg:max-w-none"
                      src="../assets/webhook.png"
                      alt="Customer profile user interface"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <img src="../assets/white-gray-transition-clean.png" /> -->
          </div>

          <!-- New Slide - API  -->
          <!-- <div class="relative bg-gray-800 pt-10 pb-0 overflow-hidden">
            <div class="relative">
              <div
                class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
              >
                <div
                  class="px-4 max-w-xl mx-auto sm:px-6 lg:py-0 lg:max-w-none lg:mx-0 lg:px-0"
                >
                  <div>
                    <div class="mt-10">
                      <h2
                        class="text-base font-semibold text-indigo-300 uppercase tracking-wide mb-10"
                      >
                        Flexible API
                      </h2>
                      <h2
                        class="text-4xl font-extrabold tracking-tight text-gray-100"
                      >
                        Build coreControls Directly into your Apps
                      </h2>
                      <p class="mt-4 mb-48 text-lg text-gray-300">
                        Whatever the coreControls Dashboard can do, you can
                        replicate in your own Applications. This gives you and
                        your customers full control over the network. Build a
                        "White Labelled" service on top of coreControls? Knock
                        yourself out!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-12 sm:mt-16 lg:mt-0">
                  <div
                    class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
                  >
                    <img
                      class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src="../assets/api.png"
                      alt="Reports"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-10">
              <img src="../assets/blue-white-transition-clean.png" />
            </div>
          </div> -->

          <!-- ## Customer Story ## -->
          <!-- <div class="relative bg-gray-200 pt-0 pb-0 overflow-hidden">
      <div
        class="py-8 bg-white overflow-hidden md:py-10 lg:py-12 lg:mx-20 lg:my-20 rounded-xl"
      >
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            role="img"
            aria-labelledby="svg-clevernet"
          >
            <title id="svg-clevernet">CLEVERNET</title>
            <defs>
              <pattern
                id="ad119f34-7694-4c31-947f-1234567"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-gray-300"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#ad119f34-7694-4c31-947f-1234567)"
            />
          </svg>

          <div class="relative">
            <img
              class="mx-auto h-12"
              src="../assets/clevernet.png"
              alt="clevernet"
            />
            <blockquote class="mt-10">
              <div
                class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-500"
              >
                <p>
                  &ldquo;coreControls provides us a single integration across
                  multiple sensor manufacturers reducing our time to
                  market&rdquo;
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="md:flex-shrink-0">
                    <img
                      class="mx-auto h-10 w-10 rounded-full"
                      src="../assets/deasy-profile.jpeg"
                      alt=""
                    />
                  </div>
                  <div
                    class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center"
                  >
                    <div class="text-base font-medium text-gray-500">
                      Shane Deasy
                    </div>

                    <svg
                      class="hidden md:block mx-1 h-5 w-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div class="text-base font-medium text-gray-500">
                      CEO, CLEVERNET
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <img src="../assets/gray-white-transition-clean.png" />
      </div>
    </div> -->

          <!-- ## Customer Story ## -->
          <!-- 
    <div
      class="py-8 bg-gray-800 overflow-hidden md:py-10 lg:py-12 lg:mx-20 lg:my-20 rounded-xl"
    >
      <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">SCRI-IS</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-500"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="404"
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div class="relative">
          <img
            class="mx-auto h-32"
            src="../assets/scriis-logo-white.svg"
            alt="scri-is"
            width="400"
          />
          <blockquote class="mt-10">
            <div
              class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-200"
            >
              <p>
                &ldquo;coreControls is literally the missing link for us to
                realise our Vision as a Company&rdquo;
              </p>
            </div>
            <footer class="mt-8">
              <div class="md:flex md:items-center md:justify-center">
                <div class="md:flex-shrink-0">
                  <img
                    class="mx-auto h-10 w-10 rounded-full"
                    src="../assets/darren-profile.png"
                    alt=""
                  />
                </div>
                <div
                  class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center"
                >
                  <div class="text-base font-medium text-gray-200">
                    Dr Darren McDonnell
                  </div>

                  <svg
                    class="hidden md:block mx-1 h-5 w-5 text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div class="text-base font-medium text-gray-200">
                    Founder, SCRI-IS
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div> -->

          <!-- ## New Slide ## Alerts ##  -->

          <!-- <div class="pt-12 white">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="lg:text-center">
                <h2
                  class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
                >
                  Alerts
                </h2>
                <p
                  class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
                >
                  Manage Incidents like a Pro
                </p>
                <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                  Out-of-the-box Network Operations Center with Industry leading
                  escalation management
                </p>
              </div>

              <div class="mt-10">
                <dl
                  class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
                >
                  <div
                    v-for="feature in alert_features"
                    :key="feature.name"
                    class="relative"
                  >
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                      >
                        <component
                          :is="feature.icon"
                          class="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p
                        class="ml-16 text-lg leading-6 font-medium text-gray-900"
                      >
                        {{ feature.name }}
                      </p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      {{ feature.description }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <img src="../assets/white-gray-transition-clean.png" />
          </div> -->

          <!-- New Slide - Reporting   -->
          <!-- <div class="py-32 bg-gray-200">
            <div
              class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
            >
              <div
                class="px-4 max-w-xl mx-auto sm:px-6 lg:py-0 lg:max-w-none lg:mx-0 lg:px-0"
              >
                <div>
                  <div class="mt-0">
                    <h2
                      class="text-3xl font-extrabold tracking-tight text-gray-900"
                    >
                      Advanced Reporting
                    </h2>
                    <dl class="mt-10 space-y-10">
                      <div
                        v-for="item in reporting_features"
                        :key="item.id"
                        class="relative"
                      >
                        <dt>
                          <div
                            class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                          >
                            <component
                              :is="item.icon"
                              class="h-6 w-6"
                              aria-hidden="true"
                            />
                          </div>
                          <p
                            class="ml-16 text-lg leading-6 font-medium text-gray-900"
                          >
                            {{ item.name }}
                          </p>
                        </dt>
                        <dd class="mt-2 ml-16 text-base text-gray-500">
                          {{ item.description }}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0">
                <div
                  class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
                >
                  <img
                    class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="../assets/reporting-image.png"
                    alt="Reports"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <!-- <img src="../assets/gray-blue-transition-clean.png" /> -->

          <!-- New Slide -  Billing -->

          <!-- <div class="relative bg-white pt-0 pb-0 overflow-hidden mt-10">
      <div class="relative">
        <div
          class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24"
        >
          <div
            class="px-4 max-w-xl mx-auto sm:px-6 lg:py-0 lg:max-w-none lg:mx-0 lg:px-0"
          >
            <div>
              <div class="mt-0">
                <h2
                  class="text-3xl font-extrabold tracking-tight text-gray-900"
                >
                  Seamless Billing
                </h2>
                <dl class="mt-10 space-y-10">
                  <div
                    v-for="item in reporting_features"
                    :key="item.id"
                    class="relative"
                  >
                    <dt>
                      <div
                        class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white"
                      >
                        <component
                          :is="item.icon"
                          class="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <p
                        class="ml-16 text-lg leading-6 font-medium text-gray-900"
                      >
                        {{ item.name }}
                      </p>
                    </dt>
                    <dd class="mt-2 ml-16 text-base text-gray-500">
                      {{ item.description }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div
              class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full"
            >
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="../assets/billing.png"
                alt="Reports"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->

          <!-- USE CASES -->

          <!-- <div class="py-0 bg-gray-800 overflow-hidden lg:py-0">
            <div
              class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl"
            >
              <svg
                class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                width="404"
                height="784"
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e8"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="784"
                  fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e8)"
                />
              </svg>

              <div
                class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
              >
                <div class="relative">
                  <div class="relative">
                    <div class="lg:px-0 mt-0">
                      <h2
                        class="text-base font-semibold text-yellow-300 uppercase tracking-wide"
                      >
                        Use Case
                      </h2>
                      <p
                        class="mt-2 mb-10 text-4xl font-extrabold text-gray-50"
                      >
                        Retail Monitoring Solution
                      </p>
                    </div>
                  </div>


                  <p class="mt-3 text-lg text-gray-100 mb-10">
                    Freshpet is a Global leader in refrigerated fresh pet food.
                    With a fleet of 25K+ refrigerators in the US and EU, they
                    had no cost effective way of monitoring the temperature of
                    their fleet. Freshpet implemented the following
                  </p>

                  <div class="mt-6 lg:mt-0 lg:col-span-1">
                    <dl
                      class="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-4 lg:gap-x-4"
                    >
                      <div
                        v-for="feature in freshpet_features"
                        :key="feature.name"
                        class="relative"
                      >
                        <dt>
                          <component
                            :is="feature.icon"
                            class="absolute h-12 w-12 text-yellow-300"
                            aria-hidden="true"
                          />
                          <CheckIcon
                  class="absolute h-6 w-6 text-yellow-300"
                  aria-hidden="true"
                />
                          <p
                            class="ml-9 text-lg leading-6 font-medium text-gray-50"
                          >
                            {{ feature.name }}
                          </p>
                        </dt>
                        <dd class="mt-2 ml-9 text-base text-gray-100">
                          {{ feature.detail }}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                  <img
                    class="relative mx-auto"
                    width="490"
                    src="../assets/freshpet.png"
                    alt=""
                  />
                </div>
              </div>

              <div class="mt-0 sm:mt-12 lg:mt-0">
                <div
                  class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-start"
                >
                  <div class="lg:col-start-1">
                    <h3
                      class="text-2xl font-extrabold tracking-tight text-gray-50 mb-5"
                    >
                      Fridge Power Anomalies
                    </h3>
                    <img
                      class="rounded-xl shadow-xl"
                      src="../assets/french_chillers.jpeg"
                    />

                    <p class="mt-3 text-lg text-gray-100 mb-10 mt-10">
                      Freshpet launched with a new retailer in Europe with a
                      coreControls gateway powered in each fridge. Every evening
                      between 6pm and 7pm alarms were sent from a number of
                      stores signifying loss of signal from the gateways. It
                      turns out the retail chain were powering the Freshpet
                      fridges from the same circuitry as the store lights
                      meaning the fridges were losing power every night.
                    </p>
                  </div>

                  <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-2">
                    <h3
                      class="text-2xl font-extrabold tracking-tight text-gray-50 mb-5"
                    >
                      Move to Preventative Maintenance
                    </h3>
                    <img
                      class="rounded-xl shadow-xl"
                      src="../assets/preventitive.jpeg"
                    />
                    <p class="mt-3 text-lg text-gray-100 mb-10 mt-10">
                      In the past, Freshpet would only service a fridge if the
                      local store reported problems. The average 'time to fix'
                      was 5 days leading to loss of sales and possible spoilage
                      of goods. With the help of coreControls, Freshpet have
                      moved to a preventative maintenance model by analysing the
                      temperature profiles across their fleet and identifying
                      issues before they happen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- Slide #Pricing -->

          <!-- This example requires Tailwind CSS v2.0+ -->

          <div class="bg-gray-700">
            <div class="px-4 pt-12 sm:px-6 lg:px-8 lg:pt-20">
              <div class="text-center">
                <h2 class="text-xl font-semibold leading-6 text-yellow-400">
                  Pricing
                </h2>
                <p
                  class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl"
                >
                  The right price for you, whoever you are
                </p>
                <p
                  class="mx-auto mt-3 max-w-4xl text-medium text-gray-300 sm:mt-5 sm:text-xl"
                >
                  We offer two packags for single site setups but can scale to
                  1,000s of sensors and sites. Contact don@corealerts.io for
                  more information on larger deployments.
                </p>
              </div>
            </div>

            <div class="bg-gray-100 pb-12 lg:mt-20 lg:pb-20">
              <div class="relative z-0">
                <div class="absolute inset-0 h-5/6 bg-gray-700 lg:h-2/3" />
                <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div class="relative lg:grid lg:grid-cols-7">
                    <div
                      class="mx-auto max-w-md lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3 lg:mx-0 lg:max-w-none"
                    >
                      <div
                        class="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg"
                      >
                        <div class="flex flex-1 flex-col">
                          <div class="bg-white px-6 py-10">
                            <div>
                              <h3
                                class="text-center text-2xl font-medium text-gray-900"
                                id="tier-starter"
                              >
                                Starter
                              </h3>
                              <div
                                class="mt-4 flex items-center justify-center"
                              >
                                <span
                                  class="flex items-start px-3 text-6xl tracking-tight text-gray-900"
                                >
                                  <span
                                    class="mt-2 mr-2 text-4xl font-medium tracking-tight"
                                    >€</span
                                  >
                                  <span class="font-bold">99</span>
                                </span>
                                <span class="text-xl font-medium text-gray-500"
                                  >/month</span
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10"
                          >
                            <ul role="list" class="space-y-4">
                              <li
                                v-for="feature in starterFeatures"
                                :key="feature"
                                class="flex items-start"
                              >
                                <div class="flex-shrink-0">
                                  <CheckIcon
                                    class="h-6 w-6 flex-shrink-0 text-green-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <p
                                  class="ml-3 text-base font-medium text-gray-500"
                                >
                                  {{ feature }}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="mx-auto mt-10 max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-0 lg:max-w-none"
                    >
                      <div class="relative z-10 rounded-lg shadow-xl">
                        <div
                          class="pointer-events-none absolute inset-0 rounded-lg border-2 border-yellow-300"
                          aria-hidden="true"
                        />
                        <div
                          class="absolute inset-x-0 top-0 translate-y-px transform"
                        >
                          <div
                            class="flex -translate-y-1/2 transform justify-center"
                          >
                            <span
                              class="inline-flex rounded-full bg-gray-500 px-4 py-1 text-base font-semibold text-white"
                              >Most popular</span
                            >
                          </div>
                        </div>
                        <div class="rounded-t-lg bg-white px-6 pt-12 pb-10">
                          <div>
                            <h3
                              class="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:-mx-6"
                              id="tier-growth"
                            >
                              Growth
                            </h3>
                            <div class="mt-4 flex items-center justify-center">
                              <span
                                class="flex items-start px-3 text-6xl tracking-tight text-gray-900 sm:text-6xl"
                              >
                                <span
                                  class="mt-2 mr-2 text-4xl font-medium tracking-tight"
                                  >€</span
                                >
                                <span class="font-bold">149</span>
                              </span>
                              <span class="text-2xl font-medium text-gray-500"
                                >/month</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="rounded-b-lg border-t-2 border-gray-100 bg-gray-50 px-6 pt-10 pb-8 sm:px-10 sm:py-10"
                        >
                          <ul role="list" class="space-y-4">
                            <li
                              v-for="feature in growthFeatures"
                              :key="feature"
                              class="flex items-start"
                            >
                              <div class="flex-shrink-0">
                                <CheckIcon
                                  class="h-6 w-6 flex-shrink-0 text-green-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <p
                                class="ml-3 text-base font-medium text-gray-500"
                              >
                                {{ feature }}
                              </p>
                            </li>
                          </ul>
                          <!-- <div class="mt-10">
                            <div class="rounded-lg shadow-md">
                              <a
                                href="#"
                                class="block w-full rounded-lg border border-transparent bg-gray-500 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-indigo-700"
                                aria-describedby="tier-growth"
                                >Start your trial</a
                              >
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="mx-auto mt-10 max-w-md lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3 lg:m-0 lg:max-w-none"
                    >
                      <div
                        class="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg"
                      >
                        <div class="flex flex-1 flex-col">
                          <div class="bg-white px-6 py-10">
                            <div>
                              <h3
                                class="text-center text-2xl font-medium text-gray-900"
                                id="tier-scale"
                              >
                                Scale
                              </h3>
                              <div
                                class="mt-4 flex items-center justify-center"
                              >
                                <span
                                  class="flex items-start px-3 text-6xl tracking-tight text-gray-900"
                                >
                                  <span
                                    class="mt-2 mr-2 text-4xl font-medium tracking-tight"
                                  ></span>
                                  <span class="font-bold">POA</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10"
                          >
                            <ul role="list" class="space-y-4">
                              <li
                                v-for="feature in scaleFeatures"
                                :key="feature"
                                class="flex items-start"
                              >
                                <div class="flex-shrink-0">
                                  <CheckIcon
                                    class="h-6 w-6 flex-shrink-0 text-green-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <p
                                  class="ml-3 text-base font-medium text-gray-500"
                                >
                                  {{ feature }}
                                </p>
                              </li>
                            </ul>
                            <!-- <div class="mt-8">
                              <div class="rounded-lg shadow-md">
                                <a
                                  href="#"
                                  class="block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-gray-600 hover:bg-gray-50"
                                  aria-describedby="tier-scale"
                                  >Start your trial</a
                                >
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- End Slide Pricing -->
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import PowerPlugOutline from 'vue-material-design-icons/PowerPlugOutline';
import CloudCheckOutline from 'vue-material-design-icons/CloudCheckOutline';
import CreditCardClockOutline from 'vue-material-design-icons/CreditCardClockOutline';
import CodeBraces from 'vue-material-design-icons/CodeBraces';
import Webhook from 'vue-material-design-icons/Webhook';
import Thermometer from 'vue-material-design-icons/Thermometer';
import RouterWireless from 'vue-material-design-icons/RouterWireless';
import DesktopMacDashboard from 'vue-material-design-icons/DesktopMacDashboard';
import DoorOpen from 'vue-material-design-icons/DoorOpen';
import Run from 'vue-material-design-icons/Run';
import {
  GlobeAltIcon,
  LightningBoltIcon,
  MailIcon,
  ScaleIcon,
  CloudUploadIcon,
  CogIcon,
  LockClosedIcon,
  RefreshIcon,
  ServerIcon,
  ShieldCheckIcon,
  CheckIcon,
  AnnotationIcon,
  InboxIcon,
  SparklesIcon,
  BellIcon,
  ChartSquareBarIcon,
  AdjustmentsIcon,
  CurrencyEuroIcon,
} from '@heroicons/vue/outline';

const navigation = [
  // { name: 'Product', href: '#' },
  // { name: 'Features', href: '/features' },
  // { name: 'Marketplace', href: '#' },
  // { name: 'Company', href: '#' },
];

const starterFeatures = [
  'Unlimited access to the coreAlerts platform',
  'purchase sensors and gateway upfront to reduce your monthly fees',
  'Escalations through text and email',
];
const scaleFeatures = [
  'For a bespoke offering contact our sales team don@corealerts.io',
];
const growthFeatures = [
  'Unlimited access to the coreAlerts platform',
  '6 sensor package including: electricity meter sensor, gas meter sensor, amp meter, 3 x temperature',
  '1 x Gateway',
  'Escalations through text, email, slack, pagerduty, jira',
];

const top_level_features = [
  {
    id: 1,
    name: 'Full-Stack Solution',
    description:
      'We provide everything from Sensors to Software. Hardware agnostic means working with sensors specific to your needs',
    icon: CloudCheckOutline,
  },
  {
    id: 2,
    name: 'Simple Installation',
    description:
      'Scan your sensor, turn it on and start tracking your assets immediately. No engineering resources required!',
    icon: PowerPlugOutline,
  },
  {
    id: 3,
    name: 'Sensor As A Service',
    description:
      'With Global GSM coverage, you pay a low monthly subscription with the ability to turn on and off datastreams',
    icon: CreditCardClockOutline,
  },
  {
    id: 4,
    name: 'Webhook and API Access',
    description:
      'Give your Developers full control over the network and datastreams',
    icon: CodeBraces,
  },
];

const alert_features = [
  {
    name: 'Network Operations Dashboard',
    description:
      'Top level dashboard views to understand the health of your network',
    icon: GlobeAltIcon,
  },
  {
    name: 'Gateway and Sensor Alerts',
    description:
      'Gateway alerts are triggered when they lose connectivity (verified independently) while Sensor alerts are configured based on the capability of the device',
    icon: BellIcon,
  },
  {
    name: 'Integration with PagerDuty',
    description:
      'Used by the top Engineering teams globally, PagerDuty helps you manage on-call schedules, escalation paths & workflows for your teams',
    icon: PowerPlugOutline,
  },
  {
    name: 'Simple Email Alerts',
    description:
      'If PagerDuty is not an option, simple email alerts are also available',
    icon: MailIcon,
  },
];

const platform_features = [
  {
    name: 'Industry leading sensors',
    detail:
      'Supplied by coreControls or bring your own - we are continuously increasing the number of compatible sensors on our platform',
    icon: Thermometer,
  },
  {
    name: '3G coreGateway',
    detail:
      'Global SIM Card included ensuring sensor connectivity is secure and independent of location',
    icon: RouterWireless,
  },
  {
    name: 'coreControls Dashboard',
    detail:
      'Manage your fleet of devices, advanced reporting, alert configuration and platform management',
    icon: DesktopMacDashboard,
  },
  {
    name: 'Full Interoperability',
    detail:
      'View the data in your coreControls Dashboard, developer access through the API or receive webhook events to record data in your own systems',
    icon: Webhook,
  },
];

const gateway_features = [
  {
    name: 'Cellular Backhaul',
    detail:
      'Global SIM Card included ensuring device connectivity is secure and independent of location',
    icon: CloudUploadIcon,
  },
  {
    name: 'SSL Certificates',
    detail:
      'Global SIM Card included ensuring sensor connectivity is secure and independent of location',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple Queues',
    detail:
      'Global SIM Card included ensuring sensor connectivity is secure and independent of location',
    icon: RefreshIcon,
  },
  {
    name: 'Advanced Security',
    detail:
      'Global SIM Card included ensuring sensor connectivity is secure and independent of location',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Powerful API',
    detail:
      'Global SIM Card included ensuring sensor connectivity is secure and independent of location',
    icon: CogIcon,
  },
  {
    name: 'Database Backups',
    detail:
      'Global SIM Card included ensuring sensor connectivity is secure and independent of location',
    icon: ServerIcon,
  },
];

const dashboard_features = [
  {
    name: 'Real-Time Energy Monitoring',
    description:
      "Fine grained, minute-to-minute monitoring of electricity and gas meters helping you understand exactly where you're using most energy. Real-time view of meter readings so you can track usage at any stage during the month",
  },
  {
    name: 'Auto Generated Weekly Reports',
    description:
      'Weekly reports that show latest meter readings and graphical views of weekly energy usage along with comparisons to previous weeks. ',
  },

  {
    name: 'Extendable Platform',
    description:
      'Easily add more sensors to the platform such as door sensors, temperature, air quality etc. Turn your building into a SMART building',
  },
  {
    name: 'Rules Based Alerts System',
    description:
      'Create IF-THIS-THEN-THAT type rules to help you create action from your data.',
  },
];

const scan_features = [
  {
    id: 1,
    name: 'Simple & Powerful',
    description:
      'Works on every mobile device, the scan function is designed to be as simple as possible for your installers in the field. All devices are pre-provisioned on the coreControls network, so just scanning the QR code will activate the device. All you need to do is give it a name',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Location Aware',
    description:
      'The GPS location of the gateways and sensors are recorded at the time of install, allowing you to easily monitor your network from a geo perspective',
    icon: MailIcon,
  },
];
// const alert_features = [
//   {
//     name: 'Competitive exchange rates',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: GlobeAltIcon,
//   },
//   {
//     name: 'No hidden fees',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: ScaleIcon,
//   },
//   {
//     name: 'Transfers are instant',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: LightningBoltIcon,
//   },
//   {
//     name: 'Mobile notifications',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: AnnotationIcon,
//   },
// ];

const reporting_features = [
  {
    id: 1,
    name: 'Sensor Level Reports',
    description:
      'Standard out-of-the-box reports for each sensor capability. Advanced search functionality by tags and text.',
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'Gateway Level Reports',
    description:
      'If multiple sensors are going through the same gateway, then advanced reporting has the ability to combine data from different sensors together. Example of this would be combining "door openings" with "traffic"',
    icon: ScaleIcon,
  },
];

const freshpet_features = [
  {
    id: 1,
    name: 'Temperature Sensors',
    detail:
      'The core Temperature Sensor has both an internal and external temperature probe, tracking internal fridge temperature and evaporator coils.',
    icon: Thermometer,
  },
  {
    id: 2,
    name: 'Reed Switches',
    detail: 'Placed on fridge doors to track door openings',
    icon: DoorOpen,
  },
  {
    id: 3,
    name: 'PIR Motion Sensors',
    detail:
      'The Core Traffic Sensor counts the amount activity in front of the fridge',
    icon: Run,
  },
];

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronRightIcon,
    MenuIcon,
    XIcon,
    CheckIcon,
    InboxIcon,
    SparklesIcon,
    Webhook,
    ChartSquareBarIcon,
    AdjustmentsIcon,
    CurrencyEuroIcon,
  },
  setup() {
    return {
      navigation,
      starterFeatures,
      scaleFeatures,
      growthFeatures,
    };
  },
  data() {
    return {
      core_client_url: '',
      signup: {
        email: '',
        service: '',
      },
      emailSubmitted: false,
      top_level_features,
      gateway_features,
      dashboard_features,
      scan_features,
      alert_features,
      reporting_features,
      platform_features,
      freshpet_features,
      panel_measure: true,
      panel_control: false,
      panel_reduce: false,
    };
  },
  created() {
    this.core_client_url = process.env.VUE_APP_CORE_CLIENT_URL;
    console.log(
      'process.env.VUE_APP_CORE_CLIENT_URL: ' +
        process.env.VUE_APP_CORE_CLIENT_URL
    );
    console.log('this.core_client_url: ' + this.core_client_url);
    console.log(this.$route.hash);
    this.$gtag.event('Home', { method: 'created' });
  },
  methods: {
    submitSignup() {
      console.log('signing up: ' + this.signup.email);
      console.log('axios.defaults.baseURL: ' + axios.defaults.baseURL);
      console.log(
        'process.env.VUE_APP_CORETHINGS_API: ' +
          process.env.VUE_APP_CORETHINGS_API
      );
      this.signup.service = 'coreControls';
      this.emailSubmitted = true;
      axios
        .post('/signups', this.signup)
        .then((res) => {
          this.serverResponse = JSON.stringify(res, null, 2);
          console.log(this.serverResponse);
        })
        .catch((err) => {
          this.serverResponse = JSON.stringify(err.response, null, 2);
          console.log(this.serverResponse);
        });

      this.$gtag.event('corecontrols-sign-up-click', {
        event_category: 'signup',
        event_label: 'sign up button',
        value: this.signup.email,
        send_to: 'AW-10937999603',
      });
    },
  },
};
</script>
